import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { navigate, Link } from "gatsby"
import Swal from 'sweetalert2'
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import _ from "lodash"

import { graphql } from "gatsby"
import styled from "styled-components"
import { connect } from "react-redux"
import { addProfileStoringNoCv } from "store/actions/profile"
import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import { seoTitleSuffix, language} from "config"

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import Grid from '@mui/material/Grid';


import contracts from "config/refs/contract_type.json"
import educations from "config/refs/education_level.json"
import schools from "config/refs/schools.json"
import experiences from "config/refs/experience_level.json"
import sectors from "config/refs/sectors.json"
import geographies from "config/refs/regions_france.json"
import languages from "config/refs/languages.json"
import languageLevels from "config/refs/languageLevels.json"


import civilities from "config/refs/civilities.json"
import genders from "config/refs/genders.json"


const forums = [ "Autre", "Paris pour l'Emploi", "SFMA", "Salon Atlas", "Bordeaux JDating", "Forum Alternance SHE"];


const StyledDivider  = styled(Divider)`
  &.MuiDivider-root {
    margin-top: 2rem;
    border-color: rgba(0, 0, 0, 1);
    font-size: 1.41168rem;
    &::before {
      width: 0%;
    }
    .MuiDivider-wrapper {
      padding-left: 0;
    }
  }
`

const StyledButton = styled(Button)`
  &.MuiButton-root {
    background-color: #255b8e;
    font-size: 0.94112rem;
    line-height: 1.1764rem;
    padding: 0.9rem 2rem;
    border-radius: 0.5882rem;
    width: 100%;

    &.MuiButton-outlined {
      margin-top: 1rem;
      background-color: unset;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      width: unset;
      &.MuiButton-outlined {
        margin-right: 1rem;
        margin-top: 0;

      }
    }
  }
`


const StyledDiv = styled.div`
  margin: auto;
  max-width: 900px;
  width: 100%;
  padding: 0 2rem;
  padding-bottom: 1rem;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.1764rem;
    color: rgb(62, 73, 84);
    margin-bottom: 1rem;
    margin-top:  2rem!important

  }
  .form_subtitle {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1764rem;
    color: rgb(157, 185, 214);
    margin-bottom: 1rem;
  }
  .MuiFormControl-root {
    .MuiTypography-root {
      font-family: basier-circle;
      font-weight: 600;
      font-size: 0.8rem;
      line-height: 0.94112rem;
      text-transform: uppercase;
      color: #324253;
      margin-bottom: 0;
    }
  }
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`

const StyledSelect = styled(Select)`
  &.MuiInputBase-root {
    fieldset {
      border-color: ${props => props.filled ? '#20bf6b' : '#fd9644'};
    }
  }
`

const StyledTextField = styled(TextField)`
  margin-bottom: 0;
  fieldset {
    border-color: ${props => props.filled ? '#20bf6b' : '#fd9644'};
  }
`

const withoutResume = React.memo(
  ({
    data,
    profileNoCv,
    addProfileStoringNoCv,
  }) => {
    const { frontmatter } = data.index.edges[0].node
    const { seoTitle, useSeoTitleSuffix, useSplashScreen } = frontmatter

    const [email, setEmail] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");

    const [gender, setGender] = React.useState("");
    const [civility, setCivility] = React.useState("");

    const [contract, setContract] = React.useState("");

    const [school, setSchool] = React.useState("");
    const [education, setEducation] = React.useState("");

    const [experience, setExperience] = React.useState("");

    const [languageList, setLanguageList] = React.useState([
      {name: "" , level: ""},
    ]);

    const [geography, setGeography] = React.useState([]);

    const [forum, setForum] = React.useState(forums[0]);
    const [sector, setSector] = React.useState("");
    const [soughtPosition, setSoughtPosition] = React.useState(null);
    const [salary, setSalary] = React.useState(null);

    const [recruiter, setRecruiter] = React.useState(null);
    const [comment, setComment] = React.useState(null)

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorFirstName, setErrorFirstName] = React.useState(false);
    const [errorLastName, setErrorLastName] = React.useState(false);


    useEffect(() => {
      if(profileNoCv.s || profileNoCv.f) {
        setLoading(false)
        if(profileNoCv.s) {
          Swal.fire({
            title: "Félicitations!",
            text: "Votre profil a été soumis avec succès.",
            icon: "success"
          });
        }
        if(profileNoCv.f) {
          Swal.fire({
            icon: 'erreur',
            title: "Ooops! Quelque chose s'est mal passé!",
            text: "Veuillez réessayer à nouveau.",
          });
        }
      }
    }, [profileNoCv])


    const globalState = {
      // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
      isIntroDone: useSplashScreen ? false : true,
      // darkMode is initially disabled, a hook inside the Layout component
      // will check the user's preferences and switch to dark mode if needed
      darkMode: true,
    }


    function validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }



    const handleConfirm = () => {
      if(validateEmail(email) && firstName && lastName) {
        let tags = [];
        let metadatas = [];
  
        tags.push({name: 'talentsoft-contractType-label', value: contract.label || null})
        tags.push({name: 'talentsoft-contractType-code', value: contract.code || null})
        tags.push({name: 'talentsoft-contractType-clientCode', value: contract.clientCode || null})
  
        tags.push({name: "talentsoft-educationLevel-label", value: education.label || null})
        tags.push({name: "talentsoft-educationLevel-code", value: education.code || null})
        tags.push({name: "talentsoft-educationLevel-clientCode", value: education.clientCode || null})
  
        tags.push({name: "talentsoft-experienceLevel-label", value: experience.label || null})
        tags.push({name: "talentsoft-experienceLevel-code", value: experience.code || null})
        tags.push({name: "talentsoft-experienceLevel-clientCode", value: experience.clientCode || null})
  
        tags.push({name: 'talentsoft-offerFamilyCategory-label', value: sector.label || null})
        tags.push({name: 'talentsoft-offerFamilyCategory-code', value: sector.code || null})
        tags.push({name: 'talentsoft-offerFamilyCategory-clientCode', value: sector.clientCode || null})
  
  
        tags.push({name: 'talentsoft-civility-label', value: civility.label || null})
        tags.push({name: 'talentsoft-civility-code', value: civility.code || null})
        tags.push({name: 'talentsoft-civility-clientCode', value: civility.clientCode || null})
  
        tags.push({name: 'talentsoft-sex-label', value: gender.label || null})
        tags.push({name: 'talentsoft-sex-code', value: gender.code || null})
        tags.push({name: 'talentsoft-sex-clientCode', value: gender.clientCode || null})
  
        tags.push({name: 'talentsoft-school-label', value: school.label || null})
        tags.push({name: 'talentsoft-school-code', value: school.code || null})
        tags.push({name: 'talentsoft-school-clientCode', value: school.clientCode || null})
  
        geography.forEach(item => {
          tags.push({name: "talentsoft-region-label", value: item.label || null})
          tags.push({name: "talentsoft-region-code", value: item.code || null})
          tags.push({name: "talentsoft-region-clientCode", value: item.clientCode || null})
         || null})
  
        languageList.forEach(item => {
          if(item.name && item.name.label) {
            tags.push({name: "talentsoft-language-label", value: item.name.label || null})
            tags.push({name: "talentsoft-language-code", value: item.name.code || null})
            tags.push({name: "talentsoft-language-clientCode", value: item.name.clientCode || null})
            tags.push({name: "talentsoft-languageLevel-label", value: `${item.name.label}::${item.level.label}` || null})
            tags.push({name: "talentsoft-languageLevel-code", value: `${item.name.code}::${item.level.code}` || null})
            tags.push({name: "talentsoft-languageLevel-clientCode", value: `${item.name.clientCode}::${item.level.clientCode}` || null})
          }
        })
  
        tags.push({name: 'salary', value: salary})
        tags.push({name: 'forum', value: forum})
        tags.push({name: 'recruiter_name', value: recruiter ? recruiter.toLowerCase().trim() : recruiter})
        tags.push({name: 'sought_position', value: soughtPosition})
        metadatas.push({name: 'comment', value: comment})
        // TODO: add availability date


        let profile = {
          info: {
            "full_name": firstName + ' ' + lastName,
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "phone": "",
            "date_birth": "",
            "location": {
              "text": "",
              "lat": null,
              "lng": null,
              "gmaps": null,
              "fields": null
            },
            "urls": [],
            "picture": null,
            "gender": "",
            "summary": ""
          },
          tags: tags,
          metadatas: metadatas,
          experiences: [],
          educations: [],
        }
        
        addProfileStoringNoCv({profile: profile, reference: email, nocv: true})

        setLoading(true)
      } else {
        if(!validateEmail(email)) {
          setError(true)
        }
        if(!firstName) {
          setErrorFirstName(true)
        }
        if(!lastName) {
          setErrorLastName(true)
        }
      }
    };


  
    const handleChange = (event) => {
      const {
         target: { value },
       } = event;
       setGeography(value);
   
     };
   
   
   
   
   
     const handleDelete = (e, value) => {
       e.preventDefault();
       const newValues = (current) => _.without(current, value)
       setGeography(newValues)
     }
   
   
     const changeLanguage = (value, index) => {
       let newValues = [...languageList]
       newValues[index].name = value
     }
   
     const changeLanguageLevel = (value, index) => {
       let newValues = [...languageList]
       newValues[index].level = value
     }
   
     const addItem =  () => {
       setLanguageList([...languageList, {name: "", level: ""}])
     }
   
     const deleteItem =  (item) => {
       let newLanguages =  [...languageList]
       newLanguages = newLanguages.filter(i => i.name.code !== item.name.code)
       setLanguageList(newLanguages)
     }


    return (
      <GlobalStateProvider initialState={globalState}>
        <Layout nocv={true}>
          <SEO
            title={
              useSeoTitleSuffix
                ? `${seoTitle} - ${seoTitleSuffix}`
                : `${seoTitle}`
            }
          />
          <StyledDiv>
            <Card sx={{ width: '100%' }}>
              {/* <CardMedia
                component="img"
                alt="sanofi intro img"
                height="140"
                image="https://www.safran-group.com//sites/default/files/2021-06/home-bg-20210413_1.jpg"
              /> */}
              <CardContent
                sx={{
                  padding: '1rem 0',
                }}
              >
                <StyledDiv>
                  <Typography variant="h1">Bonjour!</Typography>
                  <div className="form_subtitle">Merci de confirmer les informations ci-après.</div>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <StyledDivider textAlign="left">Informations personnelles</StyledDivider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          Genre
                        </Typography>
                        <StyledSelect
                          filled={!!gender}
                          value={gender}
                          renderValue={() => {
                            if (!gender.label) {
                              return <span style={{opacity: "0.38"}}>Choisissez un genre</span>;
                            }
                
                            return gender.label
                          }}
                          displayEmpty
                          onChange={event => setGender(event.target.value)}
                          size="small"
                          sx={{ marginTop: '8px'}}
                        >
                          <MenuItem disabled value="">
                            <span>Choisissez un genre</span>
                          </MenuItem>
                          {genders.map(item =>  <MenuItem value={item}>{item.label}</MenuItem>)}
                      
                        </StyledSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          Civilité
                        </Typography>
                        <StyledSelect
                          filled={civility}
                          value={civility}
                          renderValue={() => {
                            if (!civility.label) {
                              return <span style={{opacity: "0.38"}}>Choisissez une civilité</span>;
                            }
                
                            return civility.label
                          }}
                          displayEmpty
                          onChange={event => setCivility(event.target.value)}
                          size="small"
                          sx={{ marginTop: '8px'}}
                        >
                          <MenuItem disabled value="">
                            <span>Choisissez une civilité</span>
                          </MenuItem>
                          {civilities.map(item =>  <MenuItem value={item}>{item.label}</MenuItem>)}
                      
                        </StyledSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          Prénom *
                        </Typography>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="fname"
                          type="text"
                          fullWidth
                          size="small"
                          sx={{marginBottom: '0'}}
                          placeholder="Prénom"
                          value={firstName}
                          onChange={event => {
                            setErrorFirstName(false)
                            setFirstName(event.target.value)
                          }}
                          error={errorFirstName}
                          helperText={errorFirstName && "First name is required!"}
                          required
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{width: '100%'}}>
                        <Typography gutterBottom  component="label">
                        Nom *
                        </Typography>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="lname"
                          type="text"
                          fullWidth
                          sx={{marginBottom: '0'}}
                          size="small"
                          placeholder="Nom"
                          value={lastName}
                          onChange={event => {
                            setErrorLastName(false)
                            setLastName(event.target.value)
                          }}
                          error={errorLastName}
                          helperText={errorLastName && "Last name is required!"}
                          required
                        />
                      </FormControl>
                    </Grid>
                    <Grid item  xs={12} sm={6}>
                      <FormControl sx={{width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          Email *
                        </Typography>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="email"
                          sx={{marginBottom: '0'}}
                          placeholder="Adresse e-mail"
                          type="email"
                          fullWidth
                          size="small"
                          value={email}
                          onChange={event => {
                            setError(false)
                            setEmail(event.target.value)
                          }}
                          error={error}
                          helperText={error && "Invalid email!"}
                          required
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledDivider textAlign="left">Expérience professionnelle</StyledDivider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          Expérience
                        </Typography>
                        <StyledSelect
                          filled={!!experience}
                          value={experience}
                          renderValue={() => {
                            if (!experience.label) {
                              return <span style={{opacity: "0.38"}}>Choisissez une expérience</span>;
                            }
                
                            return experience.label
                          }}
                          displayEmpty
                          onChange={event => setExperience(event.target.value)}
                          size="small"
                          sx={{ marginTop: '8px'}}
                        >
                          <MenuItem disabled value="">
                            <span>Choisissez une expérience</span>
                          </MenuItem>
                          {experiences.map(item =>  <MenuItem value={item}>{item.label}</MenuItem>)}
                      
                        </StyledSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          Secteurs d’activité
                        </Typography>
                        <StyledSelect
                          filled={!!sector}
                          value={sector}
                          renderValue={() => {
                            if (!sector.label) {
                              return <span style={{opacity: "0.38"}}>Choisissez un secteurs d’activité</span>;
                            }
                
                            return sector.label
                          }}
                          displayEmpty
                          onChange={event => setSector(event.target.value)}
                          size="small"
                          sx={{ marginTop: '8px'}}
                        >
                          <MenuItem disabled value="">
                            <span>Choisissez un secteurs d’activité</span>
                          </MenuItem>
                          {sectors.map(item =>  <MenuItem value={item}>{item.label}</MenuItem>)}
                      
                        </StyledSelect>
                      </FormControl>
                    </Grid>
                    {languageList.map((lang, index) => {
                      return (
                        <Grid container sx={{margin: "auto"}}    alignItems="flex-end"  spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <FormControl sx={{ width: '100%'}}>
                              <Typography gutterBottom  component="label">
                                Langue
                              </Typography>
                              <StyledSelect
                                filled={!!lang.name}
                                value={lang.name}
                                renderValue={() => {
                                  if (!lang.name.label) {
                                    return <span style={{opacity: "0.38"}}>Choisissez une langue</span>;
                                  }

                                  return lang.name.label
                                }}
                                displayEmpty
                                onChange={event => changeLanguage(event.target.value, index)}
                                size="small"
                                sx={{ marginTop: '8px'}}
                              >
                                <MenuItem disabled value="">
                                  <span>Choisissez une langue</span>
                                </MenuItem>
                                {languages.map(item =>  <MenuItem value={item}>{item.label}</MenuItem>)}
                            
                              </StyledSelect>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <FormControl sx={{ width: '100%'}}>
                              <Typography gutterBottom  component="label">
                                Niveau de Langue
                              </Typography>
                              <StyledSelect
                                filled={!!lang.level}
                                value={lang.level}
                                renderValue={() => {
                                  if (!lang.level.label) {
                                    return <span style={{opacity: "0.38"}}>Choisissez un Niveau de Langue</span>;
                                  }
                      
                                  return lang.level.label
                                }}
                                displayEmpty
                                onChange={event => changeLanguageLevel(event.target.value, index)}
                                size="small"
                                sx={{ marginTop: '8px'}}
                              >
                                <MenuItem disabled value="">
                                  <span>Choisissez un Niveau de Langue</span>
                                </MenuItem>
                                {languageLevels.map(item =>  <MenuItem value={item}>{item.label}</MenuItem>)}
                            
                              </StyledSelect>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <IconButton onClick={() => deleteItem(lang)} aria-label="delete">
                              <DeleteIcon />
                            </IconButton>

                          </Grid>
                        </Grid>
                      )
                    })}
                    <Grid item xs={12} sx={{marginTop: '1rem'}} sm={6}>
                        <Button onClick={addItem} disabled={languageList.length === 3} sx={{borderColor: '#324253', color: '#324253', width: '100%'}} variant="outlined" startIcon={<AddIcon />}>
                        Ajouter une langue
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledDivider textAlign="left">Formation</StyledDivider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          École
                        </Typography>
                        <StyledSelect
                          filled={!!school}
                          value={school}
                          renderValue={() => {
                            if (!school.label) {
                              return <span style={{opacity: "0.38"}}>Choisissez une école</span>;
                            }
                
                            return school.label
                          }}
                          displayEmpty
                          onChange={event => setSchool(event.target.value)}
                          size="small"
                          sx={{ marginTop: '8px'}}
                        >
                          <MenuItem disabled value="">
                            <span>Choisissez une école</span>
                          </MenuItem>
                          {schools.map(item =>  <MenuItem value={item}>{item.label}</MenuItem>)}
                      
                        </StyledSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          Niveau d'études
                        </Typography>
                        <StyledSelect
                          filled={!!education}
                          value={education}
                          renderValue={() => {
                            if (!education.label) {
                              return <span style={{opacity: "0.38"}}>Choisissez un niveau d'études</span>;
                            }
                
                            return education.label
                          }}
                          displayEmpty
                          onChange={event => setEducation(event.target.value)}
                          size="small"
                          sx={{ marginTop: '8px'}}
                        >
                          <MenuItem disabled value="">
                            <span>Choisissez un niveau d'études</span>
                          </MenuItem>
                          {educations.map(item =>  <MenuItem value={item}>{item.label}</MenuItem>)}
                        </StyledSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledDivider textAlign="left">Contrat type</StyledDivider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ mb: 2, width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          Poste recherché
                        </Typography>
                        <StyledTextField
                            margin="dense"
                            id="sought_position"
                            placeholder="Poste recherché"
                            type="text"
                            fullWidth
                            size="small"
                            filled={!!soughtPosition}
                            value={soughtPosition}
                            onChange={event => {
                              setSoughtPosition(event.target.value)
                            }}
                            
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl  sx={{ width: '100%'}}>
                          <Typography gutterBottom  component="label">
                            Mobilités géographiques
                          </Typography>
                          <StyledSelect
                            filled={geography && geography.length}
                            value={geography}
                            size="small"
                            onChange={handleChange}
                            multiple
                            sx={{ marginTop: '8px'}}
                            displayEmpty
                            renderValue={() => {
                              if (geography.length === 0) {
                                return <span style={{opacity: "0.38"}}>Choisissez des Mobilités géographiques</span>;
                              }
                  
                              return geography.map(g => g.label).join(', ');
                            }}
                          >
                            <MenuItem disabled value="">
                              <span>Choisissez des Mobilités géographiques</span>
                            </MenuItem>
                            {geographies.map(item => <MenuItem key={item.code} value={item}>{item.label}</MenuItem> )}
                          </StyledSelect>
                          { geography && geography.length > 0 && (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, marginTop: '0.4rem'}}>
                              {geography.map((v) => (
                                <Chip key={v.code} value={v} clickable label={v.label} onDelete={e => handleDelete(e, v)} />
                              ))}
                            </Box>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          Contract
                        </Typography>
                        <StyledSelect
                          filled={!!contract}
                          value={contract}
                          renderValue={() => {
                            if (!contract.label) {
                              return <span style={{opacity: "0.38"}}>Choisissez un type de contract</span>;
                            }
                
                            return contract.label
                          }}
                          displayEmpty
                          onChange={event => setContract(event.target.value)}
                          size="small"
                          sx={{ marginTop: '8px'}}
                        >
                          <MenuItem disabled value="">
                            <span>Choisissez un type de contract</span>
                          </MenuItem>
                          {contracts.map(c =>  <MenuItem value={c}>{c.label}</MenuItem>)}
                      
                        </StyledSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          Salaire Fixe Annuel Brut
                        </Typography>
                        <StyledTextField
                          filled={!!salary}
                          margin="dense"
                          id="salary"
                          placeholder="Salaire Fixe Annuel Brut"
                          type="number"
                          fullWidth
                          size="small"
                          value={salary}
                          onChange={event => {
                            setSalary(event.target.value)
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledDivider textAlign="left">Rencontre avec Sanofi</StyledDivider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          Quel forum d'emploi?
                        </Typography>
                        <StyledSelect
                          filled={!!forum}
                          value={forum}
                          renderValue={() => {
                            if (!forum) {
                              return <span style={{opacity: "0.38"}}>Choisissez un forum</span>;
                            }
                
                            return forum
                          }}
                          displayEmpty
                          onChange={event => setForum(event.target.value)}
                          size="small"
                          sx={{ marginTop: '8px'}}

                        >
                          <MenuItem disabled value="">
                            <span>Choisissez un forum</span>
                          </MenuItem>
                          {forums.map(c =>  <MenuItem value={c}>{c}</MenuItem>)}
                      
                        </StyledSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          Recruteur·euse rencontré·e 
                        </Typography>
                        <StyledTextField
                          margin="dense"
                          id="recruiter"
                          placeholder="Nom du Recruteur"
                          type="text"
                          fullWidth
                          size="small"
                          filled={!!recruiter}
                          value={recruiter}
                          onChange={event => {
                            setRecruiter(event.target.value)
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl sx={{ mb: 2, width: '100%'}}>
                        <Typography gutterBottom  component="label">
                          Commentaire
                        </Typography>
                        <StyledTextField
                          margin="dense"
                          id="comment"
                          placeholder="Commentaire"
                          type="text"
                          multiline
                          rows={4}
                          fullWidth
                          size="small"
                          filled={!!comment}
                          value={comment}
                          onChange={event => {
                            setComment(event.target.value)
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </StyledDiv>
              </CardContent>
              <CardActions
                sx={{
                  padding: '1rem 2rem',
                  justifyContent: 'flex-end'
                }}
              >
                <StyledButton onClick={handleConfirm} disabled={loading} variant="contained">{loading ? <i className="fa fa-circle-o-notch fa-spin fa-fw" />  : 'Confirmer'}</StyledButton>
              </CardActions>
            </Card>

          </StyledDiv>


        </Layout>
      </GlobalStateProvider>
    )
  }
)

withoutResume.propTypes = {
  data: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  profileNoCv: state.profile.profileNoCv,
})

export default connect(mapStateToProps, { addProfileStoringNoCv })(withoutResume)

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/index/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    drop: allMdx(filter: { fileAbsolutePath: { regex: "/index/drop/" } }) {
      edges {
        node {
          frontmatter {
            title
            titlePrefix
            subtitle
            subtitlePrefix
          }
        }
      }
    }
  }
`
